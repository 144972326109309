import React, { useState } from 'react';
import { Form, Button, Row, Col, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Select from 'react-select';
import {
  format,
  addHours,
  addDays,
  addWeeks,
  addMonths,
  addYears,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears
} from 'date-fns';

export function DeviceEventReport({ backendAPI, devices }) {
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [bucketSize, setBucketSize] = useState('hourly');
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);

  const bucketOptions = ['hourly', 'daily', 'weekly', 'monthly', 'yearly'];

  // Generate options for react-select
  const deviceOptions = devices.map((device) => ({
    value: device.id,
    label: device.location,
  }));

  const handleDeviceSelection = (selectedOptions) => {
    setSelectedDevices(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  const generateTimeBuckets = (start, end, bucketSize) => {
    let currentDate = new Date(start);
    const endDate = new Date(end);
    const now = new Date();
    const timeBuckets = [];

    while (currentDate <= endDate && currentDate <= now) {
      timeBuckets.push(new Date(currentDate));
      switch (bucketSize) {
        case 'hourly':
          currentDate = addHours(currentDate, 1);
          break;
        case 'daily':
          currentDate = addDays(currentDate, 1);
          break;
        case 'weekly':
          currentDate = addWeeks(currentDate, 1);
          break;
        case 'monthly':
          currentDate = addMonths(currentDate, 1);
          break;
        case 'yearly':
          currentDate = addYears(currentDate, 1);
          break;
        default:
          break;
      }
    }
    return timeBuckets;
  };

  const fetchReport = async () => {
    if (!selectedDevices.length || !startDate || !endDate) {
      alert('Please select devices, a start date, and an end date');
      return;
    }

    // Adjust start and end dates based on bucket size
    startDate.setMinutes(0, 0, 0);
    endDate.setMinutes(0, 0, 0);
    if (bucketSize === 'daily') {
      startDate.setHours(0);
      endDate.setHours(0);
      endDate.setDate(endDate.getDate() + 1);
    } else if (bucketSize === 'weekly') {
      const startDay = startDate.getDay();
      const endDay = endDate.getDay();
      startDate.setDate(startDate.getDate() - (startDay === 0 ? 6 : startDay - 1));
      endDate.setDate(endDate.getDate() - (endDay === 0 ? 6 : endDay - 1));
      endDate.setDate(endDate.getDate() + 7);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);
    } else if (bucketSize === 'monthly') {
      startDate.setHours(0);
      startDate.setDate(1);
      endDate.setHours(0);
      endDate.setDate(1);
      endDate.setMonth(endDate.getMonth() + 1);
    } else if (bucketSize === 'yearly') {
      startDate.setHours(0);
      startDate.setMonth(0);
      startDate.setDate(1);
      endDate.setHours(0);
      endDate.setMonth(0);
      endDate.setDate(1);
      endDate.setFullYear(endDate.getFullYear() + 1);
    }

    setLoading(true);
    const response = await backendAPI.getDeviceEventReport({
      devices: selectedDevices,
      startDate,
      endDate,
      bucketSize,
    });
    setLoading(false);

    if (response && response.data) {
      const filledReportData = fillMissingBuckets(response.data.event_counts);
      setReportData(filledReportData);
    }
  };

  const fillMissingBuckets = (eventCounts) => {
    const generatedBuckets = generateTimeBuckets(startDate, endDate, bucketSize);
    return generatedBuckets.map((bucket) => {
      const formattedBucket = formatTime(bucket);
      const existingBucket = eventCounts.find(event => formatTime(event.time) === formattedBucket);
      if (existingBucket) {
        return existingBucket;
      } else {
        return {
          time: bucket,
          device_event_counts: selectedDevices.map(deviceId => ({
            device: deviceId,
            // event_count: 0
          }))
        };
      }
    });
  };

  const formatTime = (time) => {
    const date = new Date(time);
    switch (bucketSize) {
      case 'hourly':
        return format(date, 'yyyy-MM-dd HH:mm');
      case 'daily':
        return format(date, 'yyyy-MM-dd');
      case 'weekly':
        return format(date, 'yyyy-MM-dd');
      case 'monthly':
        return format(date, 'MMMM yyyy');
      case 'yearly':
        return format(date, 'yyyy');
      default:
        return time;
    }
  };

  const getReportGenerationTime = () => format(new Date(), 'yyyy-MM-dd HH:mm:ss');

  const exportToExcel = () => {
    const worksheetData = [
      [{ v: 'Time Bucket: ' + bucketSize.charAt(0).toUpperCase() + bucketSize.slice(1) }],
      [{ v: 'Date Range: ' + `${startDate ? format(startDate, 'yyyy-MM-dd') : ''} to ${endDate ? format(endDate, 'yyyy-MM-dd') : ''}` }],
      [{ v: 'Generated at: ' + getReportGenerationTime() }],
      [],
      ['Time', ...devices.filter((device) => selectedDevices.includes(device.id)).map((device) => device.location)],
      ...reportData.map((bucket) => {
        const row = [
          formatTime(bucket.time),
          ...devices
            .filter((device) => selectedDevices.includes(device.id))
            .map((device) => {
              const deviceCounts = bucket.device_event_counts.filter(
                (event) => event.device === device.id
              );
  
              if (deviceCounts.length > 0) {
                let totalCount = deviceCounts.reduce((sum, event) => sum + event.event_count, 0);
                if(isNaN(totalCount)) {
                  totalCount = 0;
                  return `Total: 0`;
                } else {
                  const breakdown = deviceCounts
                    .map(event => `${event.event_count} ${event.vehicle_class} `)
                    .join('\n');
                  return `Total: ${totalCount} - ${breakdown}`;
                }
              } else {
                return '0';
              }
            }),
        ];
        return row;
      }),
    ];
  
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
    XLSX.writeFile(workbook, 'device_event_report.xlsx');
  };
  
  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = ['Time Bucket'];
    selectedDevices.forEach((deviceId) => {
      const device = devices.find((d) => d.id === deviceId);
      tableColumn.push(device ? device.location : '');
    });

    const tableRows = reportData.map((bucket) => {
      const row = [formatTime(bucket.time)];
      devices
        .filter((device) => selectedDevices.includes(device.id))
        .forEach((device) => {
          const deviceCounts = bucket.device_event_counts.filter(
            (event) => event.device === device.id
          );

          if (deviceCounts.length > 0) {
            let totalCount = deviceCounts.reduce((sum, event) => sum + event.event_count, 0);
            if(isNaN(totalCount)) {
              totalCount = 0;
              row.push(`Total: ${totalCount}`);
            } else {
              const sortedDeviceCounts = deviceCounts.sort((a, b) => a.vehicle_class.localeCompare(b.vehicle_class));
              const breakdown = sortedDeviceCounts
                .map(event => `${event.event_count} ${event.vehicle_class}`)
                .join('\n');
              row.push(`Total: ${totalCount}\n${breakdown}`);
            }
          } else {
            row.push('Total: 0');
          }
        });

      return row;
    });

    const totalsRow = ['Totals'];
    devices
      .filter(device => selectedDevices.includes(device.id))
      .forEach(device => {
      const totalEvents = reportData.reduce((sum, bucket) => {
        const deviceCounts = bucket.device_event_counts.filter(event => event.device === device.id);
        return sum + deviceCounts.reduce((bucketSum, event) => bucketSum + (event.event_count || 0), 0);
      }, 0);

      const classificationTotals = reportData.reduce((classTotals, bucket) => {
        const deviceCounts = bucket.device_event_counts.filter(event => event.device === device.id);
        deviceCounts.forEach(event => {
        if (event.vehicle_class) {
          if (!classTotals[event.vehicle_class]) {
          classTotals[event.vehicle_class] = 0;
          }
          classTotals[event.vehicle_class] += event.event_count;
        }
        });
        return classTotals;
      }, {});

      const sortedClassificationTotals = Object.entries(classificationTotals).sort(([classA], [classB]) => classA.localeCompare(classB));
      const classificationText = sortedClassificationTotals.map(([vehicleClass, count]) => `${count} ${vehicleClass}`).join('\n');
      totalsRow.push(`Total Events: ${totalEvents}\n${classificationText}`);
      });

    tableRows.push(totalsRow);

    doc.text('Device Event Report', 14, 15);
    doc.autoTable({
      head: [['Time Bucket: ' + bucketSize.charAt(0).toUpperCase() + bucketSize.slice(1)]],
      theme: 'plain',
      startY: 18,
      margin: { left: 14 },
    });
    doc.autoTable({
      head: [['Date Range: ' + `${startDate ? format(startDate, 'yyyy-MM-dd') : ''} to ${endDate ? format(endDate, 'yyyy-MM-dd') : ''}`]],
      theme: 'plain',
      startY: 23,
      margin: { left: 14 },
    });
    doc.autoTable({
      head: [['Generated at: ' + getReportGenerationTime()]],
      theme: 'plain',
      startY: 28,
      margin: { left: 14 },
    });
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 42,
      styles: { cellPadding: 5, overflow: 'linebreak' },
    });

    

    // doc.autoTable({
    //   head: [totalsRow],
    //   body: [],
    //   startY: doc.autoTable.previous.finalY + 10,
    //   styles: { cellPadding: 5, overflow: 'linebreak' },
    // });

    doc.save('device_event_report.pdf');
  };
  
  
  
  

  return (
    <div>
      {/* <h2>Device Event Report</h2> */}

      <Form>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>Select Devices</Form.Label>
          <Col sm={10}>
            <Select
              isMulti
              options={deviceOptions}
              value={deviceOptions.filter(option => selectedDevices.includes(option.value))}
              onChange={handleDeviceSelection}
              placeholder="Select Devices"
              closeMenuOnSelect={false}
            />
          </Col>
        </Form.Group>

        <hr className="my-3" />

        {/* Bucket Size Selection */}
        <Form.Group as={Row}>
          <Form.Label column sm={2}>Bucket Size</Form.Label>
          <Col sm={10}>
            <Form.Control
              as="select"
              value={bucketSize}
              onChange={(e) => setBucketSize(e.target.value)}
            >
              {bucketOptions.map((bucket, index) => (
                <option key={index} value={bucket}>
                  {bucket.charAt(0).toUpperCase() + bucket.slice(1)}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <hr className="my-3" />

        {/* Time Range Selection */}
        <Form.Group as={Row}>
          <Form.Label column sm={2}>Start Date</Form.Label>
          <Col sm={10}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showTimeSelect={bucketSize === 'hourly'}
              dateFormat={
                bucketSize === 'hourly'
                  ? "Pp"
                  : bucketSize === 'daily'
                  ? "yyyy-MM-dd"
                  : bucketSize === 'weekly'
                  ? "yyyy-MM-dd"
                  : bucketSize === 'monthly'
                  ? "MMMM yyyy"
                  : bucketSize === 'yearly'
                  ? "yyyy"
                  : "Pp"
              }
              showMonthYearPicker={bucketSize === 'monthly'}
              showYearPicker={bucketSize === 'yearly'}
              placeholderText="Select Start Date"
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={2}>End Date</Form.Label>
          <Col sm={10}>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              showTimeSelect={bucketSize === 'hourly'}
              dateFormat={
                bucketSize === 'hourly'
                  ? "Pp"
                  : bucketSize === 'daily'
                  ? "yyyy-MM-dd"
                  : bucketSize === 'weekly'
                  ? "yyyy-MM-dd"
                  : bucketSize === 'monthly'
                  ? "MMMM yyyy"
                  : bucketSize === 'yearly'
                  ? "yyyy"
                  : "Pp"
              }
              showMonthYearPicker={bucketSize === 'monthly'}
              showYearPicker={bucketSize === 'yearly'}
              placeholderText="Select End Date"
            />
          </Col>
        </Form.Group>

        <hr className="my-3" />

        <center>
          <Button onClick={fetchReport} disabled={loading}>
            {loading ? 'Loading...' : 'Fetch Report'}
          </Button>

          <Button onClick={exportToExcel} className="ms-2">Export to Excel</Button>
          <Button onClick={exportToPDF} className="ms-2">Export to PDF</Button>
        </center>
      </Form>

      {reportData.length > 0 && <hr className="my-3" />}

      {/* Report Criteria Display */}
      {reportData.length > 0 && <p><strong>Time Bucket:</strong> {bucketSize.charAt(0).toUpperCase() + bucketSize.slice(1)}</p>}
      {reportData.length > 0 && <p><strong>Date Range:</strong> {`${startDate ? format(startDate, 'yyyy-MM-dd') : ''} to ${endDate ? format(endDate, 'yyyy-MM-dd') : ''}`}</p>}
      {reportData.length > 0 && <p><strong>Generated at:</strong> {getReportGenerationTime()}</p>}

      {/* Report Data Table */}
      {reportData.length > 0 && (
  <Table striped bordered hover className="mt-4">
    <thead>
      <tr>
        <th>Time Bucket</th>
        {devices
          .filter(device => selectedDevices.includes(device.id))
          .map(device => (
            <th key={device.id}>{device.location}</th>
          ))}
      </tr>
    </thead>
    <tbody>
      {reportData.map((bucket, index) => (
        <tr key={index}>
          <td>{formatTime(bucket.time)}</td>
          {devices
            .filter(device => selectedDevices.includes(device.id))
            .map(device => {
              const deviceCounts = bucket.device_event_counts.filter(
                (event) => event.device === device.id
              );

              if (deviceCounts.length > 0) {
                // Calculate total events for the device in this time bucket
                const totalCount = deviceCounts.reduce((sum, event) => sum + event.event_count, 0);

                // Create a breakdown of counts by class and add total
                const sortedDeviceCounts = deviceCounts.sort((a, b) => a.vehicle_class.localeCompare(b.vehicle_class));
                return (
                  <td key={device.id}>
                  <div><strong>Total: {totalCount ? totalCount : 0}</strong></div>
                  {sortedDeviceCounts.map((event, idx) => (
                    <div key={idx}>
                    {event.event_count} {event.vehicle_class}
                    </div>
                  ))}
                  </td>
                );
              } else {
                // If no data, display 0
                return <td key={device.id}><strong>Total: 0</strong></td>;
              }
            })}
        </tr>
      ))}
    </tbody>
    <tfoot>
      <tr>
        <td><strong>Totals</strong></td>
        {devices
          .filter(device => selectedDevices.includes(device.id))
          .map(device => {
            const totalEvents = reportData.reduce((sum, bucket) => {
              const deviceCounts = bucket.device_event_counts.filter(event => event.device === device.id);
              return sum + deviceCounts.reduce((bucketSum, event) => bucketSum + (event.event_count || 0), 0);
            }, 0);

            const classificationTotals = reportData.reduce((classTotals, bucket) => {
              const deviceCounts = bucket.device_event_counts.filter(event => event.device === device.id);
              deviceCounts.forEach(event => {
                if (event.vehicle_class) {
                  if(!classTotals[event.vehicle_class]) {
                    classTotals[event.vehicle_class] = 0;
                  }
                  classTotals[event.vehicle_class] += event.event_count;
                }
              });
              return classTotals;
            }, {});

            const sortedClassificationTotals = Object.entries(classificationTotals).sort(([classA], [classB]) => classA.localeCompare(classB));

            return (
              <td key={device.id}>
              <div><strong>Total Events: {totalEvents}</strong></div>
              {sortedClassificationTotals.map(([vehicleClass, count], idx) => (
                <div key={idx}>
                {count} {vehicleClass}
                </div>
              ))}
              </td>
            );
          })}
      </tr>
    </tfoot>
  </Table>
)}

    </div>
  );
}
