// ZoneSettingsModal.js
import { useState, useEffect } from 'react';
import { Modal, Button, Form, Table, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';

export function ZoneSettingsModal({ zone, submit_callback, icon, title, backendAPI }) {
    const [showModal, setShowModal] = useState(false);
    const [zoneName, setZoneName] = useState(zone ? zone.name : '');
    const [zoneDevices, setZoneDevices] = useState([]);
    const [devicesNotInZone, setDevicesNotInZone] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState('');
    const [roiId, setRoiId] = useState('');
    const [eventType, setEventType] = useState('');


    useEffect(() => {
        // if (zone) 
        //     fetchZoneDevices();
    }, [zoneDevices, devicesNotInZone]);

    async function updateDevices() {
        if (zone) {
            fetchZoneDevices();
            fetchDevicesNotInZone(zone.id);
        } else {
            fetchDevices();
        }
    }

    async function fetchDevicesNotInZone(zone_id) {
        try {
            const response = await backendAPI.getDevicesNotInZone(zone_id);
            setDevicesNotInZone(response.data.devices); 
        } catch (error) {
            console.error('Error getting zone devices:', error);
        }
    };

    async function fetchDevices(zone_id) {
        try {
            const response = await backendAPI.getDevices(0, 100);
            setDevicesNotInZone(response.data.devices); 
        } catch (error) {
            console.error('Error getting zone devices:', error);
        }
    };

    async function fetchZoneDevices() { 
        try {
            const response = await backendAPI.getZoneDevice(zone.id);
            setZoneDevices(response.data.zone_devices);
        } catch (error) {
            console.error('Error getting non-zone devices:', error);
        }
    };

    const openModal = () => {
        setZoneName(zone ? zone.name : '');
        updateDevices();
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleModalSubmit = async () => {
        if (!zoneName.trim()) {
            document.getElementById('modalError').innerText = "Zone name is required";
            return;
        }

        let updatedZone = {}
        if(zone)
            updatedZone.id = zone.id
        updatedZone.name = zoneName;

        // const updatedZone = {
        //     if(zone) {
        //         id: zone.id,
        //     }
        //     name: zoneName
        // };

        try {
            const response = await submit_callback(updatedZone);
            if (response && (response.status === 200 || response.status === 201)) {
                document.getElementById('modalError').innerText = "Success";
                setTimeout(closeModal, 1000);
            } else {
                document.getElementById('modalError').innerText = response.data.message || "Error updating zone";
            }
            updateDevices();
        } catch (error) {
            document.getElementById('modalError').innerText = "An error occurred";
            console.error('Error updating zone:', error);
        }
    };

    const removeDevice = async (zone_device_id) => {
        try {
            const response = await backendAPI.deleteZoneDevice(zone_device_id);
            if (response.status === 200) {
                updateDevices();
            }
        } catch (error) {
            console.error('Error deleting zone device:', error);
        }
    }

    const addDeviceToZone = async (device_id, roi_id, event_type) => {
        try {
            const response = await backendAPI.addZoneDevice(zone.id, device_id, roi_id, event_type);
            if (response.status === 201) {
                updateDevices();
            }
        } catch (error) {
            console.error('Error adding device to zone:', error);
        }
    }

    return (
        <div>
            <FontAwesomeIcon icon={icon} style={{ cursor: "pointer"}} onClick={openModal} />
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Zone Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={zoneName}
                                onChange={(e) => setZoneName(e.target.value)}
                                placeholder="Enter zone name"
                            />
                        </Form.Group>
                        {zone && (
                        <Form.Group className="mb-3">
                            <Table striped bordered hover className="mt-4">
                                <thead>
                                    <tr>
                                        <th>Device</th>
                                        <th>ROI</th>
                                        <th>Event Type</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {zoneDevices.map((zoneDevice, index) => (
                                        <tr key={index}>
                                            <td>{zoneDevice.device.location}</td>
                                            <td>{zoneDevice.device.roi_id}</td>
                                            <td>{zoneDevice.event_type}</td>
                                            <td>
                                                <FontAwesomeIcon icon={faTrash} style={{ color: "red", cursor: "pointer" }} onClick={() => removeDevice(zoneDevice.id)} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Row className="mt-3">
                                <Col>
                                    <FontAwesomeIcon icon={faPlus} style={{ cursor: "pointer", color: "green" }} onClick={() => setShowDropdown(!showDropdown)} />
                                    {showDropdown && (
                                        <div>
                                            <Form.Control as="select" onChange={(e) => setSelectedDevice(e.target.value)}>
                                                <option value="">Select a device</option>
                                                {devicesNotInZone.map((device) => (
                                                    <option key={device.id} value={device.id}>
                                                        {device.location}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter ROI ID"
                                                className="mt-2"
                                                onChange={(e) => setRoiId(e.target.value)}
                                            />
                                            <Form.Label className="mt-2">Event Type</Form.Label>
                                            <div className="mt-2">
                                                <Form.Check
                                                    type="radio"
                                                    label="ENTRY"
                                                    name="eventType"
                                                    value="ENTRY"
                                                    onChange={(e) => setEventType(e.target.value)}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    label="EXIT"
                                                    name="eventType"
                                                    value="EXIT"
                                                    onChange={(e) => setEventType(e.target.value)}
                                                />
                                            </div>
                                            <Button variant="primary" className="mt-2" onClick={() => addDeviceToZone(selectedDevice, roiId, eventType)}>Add Device</Button>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Form.Group>
                        )}
                    </Form>
                    <center>
                        <label id="modalError" style={{ color: 'red' }}></label>
                    </center>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Cancel</Button>
                    <Button variant="primary" onClick={handleModalSubmit}>Save Changes</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
